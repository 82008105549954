<template>
  <div>
    <div :class="[show_modal_filter ? 'wrapper-modal-filter' : 'hidden']" @click="closeModalFilter">
      <div class="modal-filter">
        <div class="list-filter" v-for="(data, index) in optionsFilter" :key="index" :class="{select: data.value === selectedFilter.value}" @click="handleSelectFilter(data)">
          {{data.label}}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="d-flex align-items-center" style="width: 100%">
        <div class="back-button feedback" @click="goBack">
          <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
        </div>
        <div style="width: 100%">
          <div class="text-redeem">
            {{$t('my_voucher')}}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center  mx-4">
        <div class="wrapper-image">
          <img v-if="getDataCustomer.image" :src="getDataCustomer.image" alt="">
          <img  v-else src="@/assets/images/profile.jpg" alt="">
        </div>
        <div class="ml-3">
          <h3>{{getDataCustomer.name}}</h3>
          <h6>{{getDataCustomer.email}}</h6>
        </div>
      </div>
      <!-- <div class="line" /> -->
      <div class="d-flex justify-content-between align-items-center text-points">
        <div>{{$t('my_point')}} : {{getCustomerPoints}}</div>
        <div class="d-flex align-items-center">
          <div>{{$t('voucher_total')}} {{getDataCustomerVoucherPersonal.length}}</div>
          <div class="badge badge-primary ml-2" @click="show_modal_filter = !show_modal_filter">{{selectedFilter.label}}</div>
        </div>
      </div>
      <div class="wrapper-list-voucher">
        <div v-if="getDataCustomerVoucherPersonal.length">
          <div class="list-voucher mb-2" v-for="data in getDataCustomerVoucherPersonal" :key="data.id">
            <div class="list-voucher-left">
              <div class="business-outlet">{{data.Customer_Voucher_List.Business.name}} - {{data.Outlet.name}}</div>
              <div class="amount-discount" v-if="data.Customer_Voucher_List.discount_type === 'amount'">Diskon Rp. {{data.Customer_Voucher_List.discount_amount | numFormat}}</div>
              <div class="amount-discount" v-else>Diskon {{data.Customer_Voucher_List.discount_amount}}%</div>
              <div class="max-shopping" v-if="data.Customer_Voucher_List.discount_type === 'percentage' && data.Customer_Voucher_List.discount_limit">Max. belanja Rp. {{data.Customer_Voucher_List.discount_limit | numFormat}}</div>
              <div class="expiration-date">
                Until {{data.Customer_Voucher_List.format_expiration_date}}
              </div>
              <div class="expiration-date">Expired {{data.Customer_Voucher_List.format_form_now}}</div>
            </div>
            <!-- <div class="list-voucher-right">
              <div class="spent-button">{{data.acquisition_cost}} Point</div>
            </div> -->
          </div>
        </div>
        <h5 v-else class="text-center text-muted mt-5 font-weight-bold">
          Voucher not Found
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'

export default {
  name: 'Redeem',
  data () {
    const optionsFilter = [
      {
        value: 'all',
        label: 'All'
      },
      {
        value: 'amount',
        label: 'Amount'
      },
      {
        value: 'percentage',
        label: 'Percentage'
      }
    ]
    return {
      customerAccountId: localStorage.getItem('customer_account_id'),
      acquisition_type: 'point',
      show_modal_filter: false,
      optionsFilter,
      selectedFilter: {
        value: 'all',
        label: 'All'
      }
    }
  },
  mounted () {
    this.handleCustomerVoucherPersonal({ customer_account_id: this.customerAccountId })
    this.handleVoucherCustomer({ acquisition_type: this.acquisition_type })
  },
  methods: {
    ...mapActions(['handleVoucherCustomer', 'handleCustomerVoucherPersonal']),
    ...mapMutations(['SET_DATA_CUSTOMER_VOUCHER_PERSONAL']),
    async handleSelectFilter (data) {
      try {
        this.selectedFilter = data
        if (data.value === 'all') {
          await this.handleCustomerVoucherPersonal({ customer_account_id: this.customerAccountId })
        } else {
          const resList = await this.handleCustomerVoucherPersonal({ customer_account_id: this.customerAccountId })
          const filterList = resList.filter(value => {
            return value.Customer_Voucher_List.discount_type === data.value
          })
          this.SET_DATA_CUSTOMER_VOUCHER_PERSONAL(filterList)
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    closeModalFilter () {
      this.show_modal_filter = !this.show_modal_filter
    },
    pushTo (routeName) {
      this.$router.push({ name: routeName })
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer', 'getDataVoucherCustomer', 'getCustomerId', 'getDataCustomerVoucherPersonal', 'getCustomerPoints'])
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

h3, h6{
  padding: 0;
  margin: 0;
}
.content{
  height: 90vh;
  overflow-y: auto;
  padding: 10px 20px;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-redeem{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrapper-image{
  width: 80px;
  height: 80px;
}
.wrapper-image>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.line{
  width: 100%;
  height: 12px;
  background-color: #E7E7FF;
  border-radius: 10px;
  margin: 10px 0;
}
::-webkit-scrollbar {
  width: 2px;
  height: 0;
}
.wrapper-list-voucher{
  height: 62vh;
  overflow: auto;
}
.list-voucher{
  border-radius: 3px;
  display: flex;
  background-color: #E7E7FF;
  /* padding: 25px; */
}
.list-voucher-left{
  width: 65%;
  padding: 15px;
  color: #6B4EFF;
  border: none;
}
.list-voucher-right{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  border: none;
  border-left-width: 0.4px;
  border-left-style: dashed;
  border-left-color: grey;
  margin: 5px 0;
}
.spent-button{
  font-size: 0.8rem;
  padding: 5px 20px;
  border-radius: 2px;
  color: white;
  background-color: #6B4EFF;
}
.business-outlet{
  font-size: 0.8rem;
}
.amount-discount{
  font-size: 1.2rem;
  font-weight: 600;
}
.max-shopping{
  font-size: 0.8rem;
}
.expiration-date{
  color: #6C757E;
  font-size: 0.8rem;
}
.text-points{
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}
.badge-primary{
  width: 75px;
  padding: 10px 0;
  background-color: #6B4EFF;
}
.wrapper-modal-filter{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-filter{
  padding: 0 10px;
  padding-top: 10px;
  width: 70%;
  background-color: white;
  border-radius: 5px;
}
.list-filter{
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.list-filter:hover{
  background-color: #6B4EFF;
  color: white;
}
.hidden{
  display: none;
}
.select{
  background-color: #6B4EFF;
  color: white;
}
</style>
