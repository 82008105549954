<template>
  <div>
    <div v-if="jumbotronCategory.title && jumbotronCategory.publishedAt">
      <div class="wrapper-content">
        <div @click="openFeed(jumbotronCategory)" class="feedback">
          <div class="wrapper-image-jumbotron">
            <img :src="jumbotronCategory.urlToImage" alt="Article Jumbotron" @error="handlePlaceholderImg" v-if="jumbotronCategory.urlToImage">
            <!--  -->
            <img src="@/assets/images/websiteplanet-dummy-700X400.png" alt="Article Jumbotron" @error="handlePlaceholderImg" v-else>
          </div>
          <div class="title-feed">
            {{jumbotronCategory.title}}
          </div>
          <div class="description" v-html="jumbotronCategory.content"></div>
          <div class="created-feed">
            {{jumbotronCategory.publishedAt}} - by {{jumbotronCategory.author}}
          </div>
        </div>
        <div class="list-feed mt-4">
          <div class="d-flex mb-4 feedback" v-for="(data, index) in categoryList" :key="index" @click="openFeed(data)">
            <div class="container-image-list">
              <div class="wrapper-list-image">
                <img :src="data.urlToImage" alt="Article" @error="handlePlaceholderImg2" v-if="data.urlToImage">
                <img src="@/assets/images/websiteplanet-dummy-700X400.png" alt="Article Jumbotron" @error="handlePlaceholderImg" v-else>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between container-title-list">
              <div class="title-feed">{{data.title}}</div>
              <div class="description" v-html="data.content"></div>
              <div class="created-feed">{{data.publishedAt}} - by {{data.author}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="empty-data text-muted">
        {{$t('data_not_found')}}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mapActions, mapGetters } from 'vuex';
dayjs.extend(relativeTime)

export default {
  name: 'CategoryFeed',
  data() {
    return {
      stateModalNews: false,
      jumbotronCategory: {
        author: String,
        title: String,
        image: String,
        created: String
      },
      urlNews: '',
      categoryList: [],
      allFeedOfAllCategory: [
        {
          id: 1,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/200/300',
          created: '2h ago . by Hanif',
          category: 'news'
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/250/300',
          created: '2h ago . by Lucky',
          category: 'news'
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/300/300',
          created: '2h ago . by Yogi',
          category: 'news'
        },
        {
          id: 4,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Dicky',
          category: 'news'
        },
        {
          id: 5,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Lucky',
          category: 'promo'
        },
        {
          id: 6,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/300/300',
          created: '2h ago . by Yogi',
          category: 'promo'
        },
        {
          id: 7,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/350',
          created: '2h ago . by Hanif',
          category: 'promo'
        },
        {
          id: 8,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Dicky',
          category: 'promo'
        },
        {
          id: 9,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Lucky',
          category: 'overview'
        },
        {
          id: 10,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/300/300',
          created: '2h ago . by Yogi',
          category: 'overview'
        },
        {
          id: 11,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/350',
          created: '2h ago . by Hanif',
          category: 'overview'
        },
        {
          id: 12,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Dicky',
          category: 'overview'
        }
      ],
      // categoryName: this.$route.params.categoryName
    }
  },
  mounted () {
    this.handleNews()
    this.handleListCategory()
  },
  methods: {
    openFeed (data) {
      console.log('openFeed', data)
      this.$router.push({name: 'DetailFeed', params: {id: data.id}})
    },
    handleOpenModalNews (data) {
      this.urlNews = data.url
      window.open(data.url, '_blank');
    },
    handleCloseModalNews () {
      this.stateModalNews = false
    },
    handlePlaceholderImg (e) {
      e.target.src = 'https://dummyimage.com/900x600/000000/ffffff&text=Image+not+found'
    },
    handlePlaceholderImg2 (e) {
      e.target.src = 'https://dummyimage.com/900x600/000000/ffffff&text=Image+not+found'
    },
    handleListCategory () {
    },
    handleNews() {
      const articles = this.getAllFeedOfAllCategory
      console.log('articles', articles)

      let tempCategory = []
      if (articles.length > 0) {
        this.jumbotronCategory = {
          id: articles[0].id,
          author: articles[0].author,
          title: articles[0].title,
          content: articles[0].content && articles[0].content.length > 100 ? `${this.escapeHtml(articles[0].content).substr(0, 170)} . . .` : articles[0].content.length && articles[0].content.length < 100 ? `${this.escapeHtml(articles[0].content)} . . .` : 'content not found',
          urlToImage: articles[0].urlToImage,
          publishedAt: dayjs(articles[0].publishedAt).fromNow()
        }
        tempCategory = articles.slice(1, articles.length)
      } else {
        this.jumbotronCategory = {}
        this.categoryList = []
      }

      console.log('tempCategory', tempCategory)

      if (tempCategory.length > 0) {
        const result = []
        tempCategory.map(value => {
          const data = {
            ...value,
            content: value.content && value.content.length > 100 ? `${this.escapeHtml(value.content).substr(0, 170)} . . .` : value.content.length && value.content.length < 100 ? `${this.escapeHtml(value.content)} . . .` : 'content not found',
            publishedAt: dayjs(value.publishedAt).fromNow()
          }
          result.push(data)
        })
        this.categoryList = result
      }
    },
    escapeHtml(unsafe) {
      return unsafe
        //  .replace(/&/g, "&amp;")
        //  .replace(/</g, "&lt;")
        //  .replace(/>/g, "&gt;")
        //  .replace(/"/g, "&quot;")
        //  .replace(/'/g, "&#039;");
    }
  },
  watch: {
    $route (to) {
      this.categoryName = to.params.categoryName
      this.handleListCategory()
    },
    getLoading (val){
      console.log("Get Loading", val)
      this.handleNews()
    }
  },
  computed: {
    ...mapGetters(['getLoading', 'getAllFeedOfAllCategory'])
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}

.wrapper-content{
  height: 65vh;
  overflow: auto;
}
.title-feed{
  font-size: 01rem;
  font-weight: 600;
  margin: 5px 0;
}
.created-feed{
  font-size: 0.8rem;
  color: #999999;
}
.empty-data{
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
}
.wrapper-image-jumbotron{
  width: 100%;
  height: 300px;
}
.wrapper-image-jumbotron > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper-list-image{
  width: 9rem;
  height: 9rem;
}
.wrapper-list-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container-image-list{
  width: 200px;
}
.container-title-list {
  width: 100%;
}
.description{
  display: block;
}

.custom-modal{
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.modal-box{
  background-color: rgb(242,247,252);
  box-shadow: 0 0 3px 1px #6B4EFF;
  width: 100vw;
}
.modal-box > iframe {
  width: 100%;
  height: 100%;
}
.header-custom-modal {
  background-color: rgb(242,247,252);
}
.wrapper-icon-close{
  margin: 10px 25px;
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.wrapper-icon-close>img{
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .description{
    display: none;
  }
  .wrapper-image-jumbotron{
    width: 100%;
    height: 200px;
  }
  .wrapper-image-jumbotron > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .wrapper-list-image{
    width: 5rem;
    height: 5rem;
  }
  .wrapper-list-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container-image-list{
    width: 120px;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: 495px) {
}
</style>
