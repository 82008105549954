<template>
  <div class="container-content">
    <div class="wrapper-content">
      <router-view />
    </div>
    <div class="wrapper-bottom">
      <div class="d-flex flex-column justify-content-center align-items-center click-feedback" @click="pushTo('AllCategoryFeed', 'feed')">
        <div class="wrapper-icon">
          <img src="@/assets/images/Feed Active.png" alt="Feed" v-if="pageActive === 'feed'">
          <img src="@/assets/images/Feed Inactive.png" alt="Feed" v-else>
        </div>
        <div style="font-weight: 600" :class="[pageActive === 'feed' ? 'active' : 'inactive', 'title-menu']">{{$t('feed')}}</div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="wrapper-icon">
          <img src="@/assets/images/Promo Inactive.png" alt="Promo">
        </div>
        <div style="font-weight: 600" class="title-menu text-muted">{{$t('promo')}}</div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center click-feedback" @click="pushTo('Notification', 'notification')">
        <div class="wrapper-icon">
          <img src="@/assets/images/Notification Active.png" alt="Notification" v-if="pageActive === 'notification'">
          <img src="@/assets/images/Notification Inactive.png" alt="Notification" v-else>
        </div>
        <div style="font-weight: 600" :class="[pageActive === 'notification' ? 'active' : 'inactive', 'title-menu']" >{{$t('notification')}}</div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center click-feedback" @click="pushTo('Account', 'account')">
        <div class="wrapper-icon">
          <img src="@/assets/images/Account Active.png" alt="Me" v-if="pageActive === 'account'">
          <img src="@/assets/images/Account Inactive.png" alt="Me" v-else>
        </div>
        <div style="font-weight: 600" :class="[pageActive === 'account' ? 'active' : 'inactive', 'title-menu']">{{$t('me')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
  name: 'Main',
  data () {
    return {
      pageActive: 'feed'
    }
  },
  mounted () {
    this.handleDataCustomer({ id: localStorage.getItem('customer_account_id') })
  },
  methods: {
    ...mapActions(['handleDataCustomer']),
    pushTo (routeName, pageName) {
      this.$router.push({ name: routeName })
      this.pageActive = pageName
    }
  }
}
</script>

<style scoped>
.container-content{
  position: relative;
  height: 100vh;
}

.wrapper-bottom{
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
}
.wrapper-content{
  height: 100%;
  overflow-y: auto;
}
.inactive{
  color: #B2B5B6;
}
.active{
  color: #9985FF;
}
</style>
