<template>
  <div class="content">
    <div>
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <h2 class="welcome-babyshop">Welcome to Beet Loyality Promo.</h2>
      <h6>Sign up to your account</h6>
      <div class="title-input mt-4" for="name">Name</div>
      <input class="name" type="text" id="name" v-model="name">
      <div class="title-input" for="phone-number">Mobile Phone Number</div>
      <div class="d-flex justify-content-between">
        <vue-country-code
          enableSearchField=true
          enabledCountryCode=true
          class="option-country-code"
          @onSelect="onSelect"
          :preferredCountries="['id', 'us', 'gb']">
        </vue-country-code>
        <input class="phone-number" type="text" placeholder="Phone Number" id="phone-number" v-model="phoneNumber">
      </div>
      <div class="title-input" for="email">Email</div>
      <input class="email" type="text" id="email" v-model="email">
      <p class="text-danger" v-if="email.length >=1 && !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)">email must be a valid email</p>
      <div class="title-input" for="password">Password</div>
      <input class="password" type="password" id="password" v-model="password">
      <p class="text-danger" v-if="password.length >=1 && !password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,20}$/)">password should be combination of one uppercase , one lower case, one number and min 8, max 20 char long</p>
    </div>
    <div>
      <div :class="[name && email && phoneNumber && password ? 'active' : 'inactive', 'button']" @click="handleRegister">
        Register
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
  name: 'Register',
  data () {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
      countryCode: {
        dialCode: '',
        iso2: ''
      }
    }
  },
  mounted () {
    this.firstRender()
  },
  methods: {
    ...mapActions(['register', 'sendOtpEmail']),
    firstRender () {
      console.log('Hello World')
    },
    pushToLoginEmail () {
      this.$router.push({ name: 'LoginEmail' })
    },
    onSelect (event) {
      console.log('event', event)
      this.countryCode = {
        dialCode: event.dialCode,
        iso2: event.iso2
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    async handleRegister () {
      if (this.email.length >= 1 && !this.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) || this.password.length >= 1 && !this.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,20}$/) || !this.name.length || !this.phoneNumber) {

      } else {
        const payload = {
          name: this.name,
          phone_number: this.countryCode.dialCode + this.phoneNumber,
          email: this.email,
          password: this.password,
          code_4: 1
        }
        try {
          const dataRegistrasi = await this.register(payload)
          await this.handleSendOtpEmail(dataRegistrasi.result)
          this.$router.push({ name: 'RegisterVerificationEmail', params: { session: dataRegistrasi.accessToken } })
        } catch (error) {
          console.log('error register', error)
          Swal.fire(
            error,
            '',
            'error'
          )
        }
      }
    },
    async handleSendOtpEmail (dataRegistrasi) {
      try {
        await this.sendOtpEmail({ email: this.email, code: dataRegistrasi.verification_code })
        this.$toasted.success('OTP verification sent, please check your email', {
          theme: 'bubble',
          position: 'top-right',
          duration: 7000
        })
      } catch (error) {
        this.$toasted.error('Verify code not sent, please try again', {
          theme: 'bubble',
          position: 'top-right',
          duration: 5000
        })
      }
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.text-danger{
  line-height: 120%
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.back-button{
  margin: 20px 0;
}
.option-country-code{
  padding: 3px 5px;
}
input.phone-number{
  width: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid #BBBBBB;
  margin-left: -2px;
  padding-left: 8px;
}
input.phone-number:focus, .option-country-code:focus{
  outline: 0.3px #6B4EFF solid;
}
.desc-muted{
  margin-top: 10px;
  font-size: .8rem;
}
.button{
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.button.active{
  background-color: #6B4EFF;
  color: white;
  cursor: pointer;
}
.button.inactive{
  background-color: #E3E5E6;
  color: #979C9E;
}
.button-email{
  text-align: center;
  margin: 20px 0;
  color: #6B4EFF;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
input.email, input.password, input.name{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  margin-left: -2px;
  /* padding: 8px 8px; */
  text-justify: auto;
  height: 43px;
  padding-left: 7px;
}
input.email:focus, input.password:focus, input.name:focus , .option-country-code:focus{
  outline: 0.3px #6B4EFF solid;
}
.title-input{
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 4px;
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 495px) {
  .welcome-babyshop{
    width: 50%;
  }
}
</style>
