<template>
  <div class="container-account">
    <div class="d-flex align-items-center">
      <div class="wrapper-left">
        <div class="wrapper-image">
          <img v-if="getDataCustomer.image" :src="getDataCustomer.image" alt="">
          <img v-else src="@/assets/images/profile.jpg" alt="">
        </div>
      </div>
      <div class="wrapper-right">
        <h4>{{getDataCustomer.name}}</h4>
        <p>{{getDataCustomer.email}}</p>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="text-balance" style="font-weight: 600">{{$t('my_saldo')}} : Rp. <span>{{getDataCustomer.saldo_amount | numFormat}}</span></div>
      <div class="text-points" style="font-weight: 600">{{$t('my_point')}} : <span>{{getCustomerPoints}}</span></div>
    </div>
    <div class="line" />
    <div class="button click-feedback" @click="pushTo('Profile')">
      {{$t('view_profile')}}
    </div>
    <div class="d-flex align-items-center mt-4 text-muted">
      <div class="mr-3">
        <img src="@/assets/images/Address.png" alt="">
      </div>
      <div style="font-weight: 600">{{$t('address')}}</div>
    </div>
    <!-- <div class="d-flex align-items-center mt-4" @click="pushTo('Membership')">
      <div class="mr-3">
        <img src="@/assets/images/Membership.png" alt="">
      </div>
      <div>Membership</div>
    </div> -->
    <div class="d-flex align-items-center mt-4 click-feedback" @click="pushTo('Topup')">
      <div class="mr-3 wrapper-icon">
        <img src="@/assets/images/plus.png" alt="">
      </div>
      <div style="font-weight: 600">{{$t('top_up')}}</div>
    </div>
    <div class="d-flex align-items-center mt-4 click-feedback" @click="pushTo('Redeem')">
      <div class="mr-3">
        <img src="@/assets/images/Membership.png" alt="">
      </div>
      <div style="font-weight: 600">{{$t('redeem')}}</div>
    </div>
    <div class="d-flex align-items-center mt-4 click-feedback" @click="pushTo('PersonalVoucher')">
      <div class="mr-3">
        <img src="@/assets/images/Membership.png" alt="">
      </div>
      <div style="font-weight: 600">{{$t('my_voucher')}}</div>
    </div>
    <div class="d-flex align-items-center mt-4 text-muted">
      <div class="mr-3">
        <img src="@/assets/images/Help.png" alt="">
      </div>
      <div style="font-weight: 600">{{$t('help')}}</div>
    </div>
    <div class="d-flex align-items-center mt-4 text-muted">
      <div class="mr-3">
        <img src="@/assets/images/Settings.png" alt="">
      </div>
      <div style="font-weight: 600">{{$t('settings')}}</div>
    </div>
    <hr class="my-5"/>
    <div class="text-muted" style="font-weight: 600">{{$t('about')}}</div>
    <div class="mt-4 click-feedback" @click="handleLogout" style="font-weight: 600">{{$t('logout')}}</div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Account',
  methods: {
    ...mapActions(['logout']),
    pushTo (routeName) {
      this.$router.push({ name: routeName })
    },
    handleLogout () {
      this.logout()
      localStorage.clear()
      this.$router.push({ name: 'Auth' })
      Swal.fire('Logout success', '', 'success')
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer', 'getCustomerPoints'])
  }
}
</script>

<style scoped>
h4, p{
  margin: 0
}
.container-account{
  padding: 10px 20px;
  height: 90vh;
  overflow: auto;
}
.wrapper-left{
  width: 110px;
}
.wrapper-image{
  width: 80px;
  height: 80px;
}
.wrapper-image>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.wrapper-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.line{
  width: 100%;
  height: 12px;
  background-color: #E7E7FF;
  border-radius: 10px;
  margin: 10px 0;
}
.button{
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #E7E7FF;
  color: #6B4EFF;
  font-weight: 600;
}
.text-points, .text-balance{
  font-size: 1rem;
  font-weight: 600;
  /* text-align: center; */
  margin-top: 20px;
}
.wrapper-icon{
  width: 23px;
}
.wrapper-icon > img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
</style>
