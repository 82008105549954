<template>
  <div>
    <div :class="[show_modal_filter ? 'wrapper-modal-filter' : 'hidden']" @click="closeModalFilter">
      <div class="modal-filter">
        <div class="list-filter" v-for="(data, index) in optionsFilter" :key="index" :class="{select: data.value === selectedFilter.value}" @click="handleSelectFilter(data)">
          {{data.label}}
        </div>
      </div>
    </div>
    <div v-if="stateModalCz" class="custom-modal">
      <div class="modal-box">
        <div class="d-flex justify-content-end header-custom-modal">
          <div class="wrapper-icon-close" @click="handleCloseModal">
            <img src="@/assets/images/icons8-macos-close-90.png" alt="Close">
          </div>
        </div>
        <iframe class="iframe-cz" :src="urlVendor" allow="geolocation" frameborder="0"></iframe>
      </div>
    </div>
    <div class="content">
      <div class="d-flex align-items-center" style="width: 100%">
        <div class="back-button feedback" @click="goBack">
          <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
        </div>
        <div style="width: 100%">
          <div class="text-redeem">
            {{$t('redeem')}}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center  mx-4">
        <div class="wrapper-image">
          <img v-if="getDataCustomer.image" :src="getDataCustomer.image" alt="">
          <img  v-else src="@/assets/images/profile.jpg" alt="">
        </div>
        <div class="ml-3">
          <h3>{{getDataCustomer.name}}</h3>
          <h6>{{getDataCustomer.email}}</h6>
        </div>
      </div>
      <!-- <div class="line" /> -->
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="text-points">{{$t('my_point')}} : {{getCustomerPoints}}</div>
          <div class="text-points">{{$t('my_saldo')}} : Rp. {{getDataCustomer.saldo_amount | numFormat}}</div>
        </div>
        <div class="badge badge-primary feedback" @click="show_modal_filter = !show_modal_filter">{{selectedFilter.label}}</div>
        <!-- <div class="badge badge-primary" @click="handleType">{{acquisition_type}}</div> -->
      </div>
      <div class="wrapper-list-voucher">
        <div v-if="voucherCustomer.length">
          <div v-for="data in voucherCustomer" :key="data.id">
            <div class="list-voucher mb-2" v-if="data.limit_claim > 0">
              <div class="list-voucher-left">
                <div class="business-outlet">{{data.Business.name}} - {{data.Outlet.name}}</div>
                <div class="amount-discount" v-if="data.discount_type === 'amount'">Diskon Rp. {{data.discount_amount | numFormat}}</div>
                <div class="amount-discount" v-else>Diskon {{data.discount_amount}}%</div>
                <div class="max-shopping" v-if="data.discount_type === 'percentage' && data.discount_limit">Max. belanja Rp. {{data.discount_limit | numFormat}}</div>
                <div class="expiration-date">
                  Until {{data.format_expiration_date}}
                </div>
                <div class="expiration-date">Expired {{data.format_from_now}}</div>
              </div>
              <div class="list-voucher-right">
                <div :class="[data.claim_amount >= data.limit_claim ? 'vouchers-out' : 'spent-button', 'feedback']" @click="handleRedeem(data)" v-if="data.acquisition_type === 'point'">{{data.acquisition_cost}} Point</div>
                <!-- <div class="spent-button" @click="handleCZ(data)" v-else>Rp. {{data.acquisition_cost | numFormat}}</div> -->
                <div class="spent-button feedback" @click="handleRedeem(data)" v-else>Rp. {{data.acquisition_cost | numFormat}}</div>
              </div>
            </div>
          </div>
        </div>
        <h5 v-else class="text-center text-muted mt-5 font-weight-bold">
          Voucher not Found
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import randomstring from 'randomstring'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  name: 'Redeem',
  data () {
    const optionsFilter = [
      {
        value: 'all',
        label: 'All'
      },
      {
        value: 'point',
        label: 'Point'
      },
      {
        value: 'currency',
        label: 'Saldo'
      }
    ]
    return {
      customerAccountId: localStorage.getItem('customer_account_id'),
      acquisition_type: 'all',
      urlVendor: '',
      stateModalCz: false,
      TRANSIDMERCHANT: randomstring.generate(12),
      dataEdit: {},
      show_modal_filter: false,
      optionsFilter,
      selectedFilter: {
        value: 'all',
        label: 'All'
      }
    }
  },
  mounted () {
    this.handleCustomerVoucherPersonal({ customer_account_id: this.customerAccountId })
    this.handleVoucherCustomer({ acquisition_type: this.acquisition_type })
  },
  methods: {
    ...mapActions(['handleVoucherCustomer', 'handleCustomerVoucherPersonal', 'handleDataCustomer', 'handlePaymentMethodBusiness']),
    async handleSelectFilter (data) {
      try {
        console.log('data', data)
        this.selectedFilter = data
        this.acquisition_type = data.value
      } catch (error) {
        console.log('error', error)
      }
    },
    closeModalFilter () {
      this.show_modal_filter = !this.show_modal_filter
    },
    handleCloseModal () {
      this.stateModalCz = false
    },
    pushTo (routeName) {
      this.$router.push({ name: routeName })
    },
    goBack () {
      this.$router.go(-1)
    },
    async handleRedeem (dataEdit) {
      if (dataEdit.claim_amount >= dataEdit.limit_claim) {
        this.$toasted.error('Cannot claim Voucher. Claim limit has been exceeded', {
          theme: 'bubble',
          position: 'top-right',
          duration: 4500
        })
        return
      }
      try {
        const date = new Date()
        const date_now = dayjs(date).format('YYYY-MM-DD')

        const start_date = `${date_now} 00:00:59`
        const end_date = `${date_now} 23:59:59`

        const data_customer_voucher_redeem = {
          outlet_id: dataEdit.outlet_id,
          customer_id: this.getCustomerId,
          voucher_id: dataEdit.id,
          start_date,
          end_date
        }

        await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-voucher-redeem/redeem`, data_customer_voucher_redeem)

        if (dataEdit.acquisition_type === 'currency') {
          const reduce_saldo_customer = {
            customer_account_id: this.customerAccountId,
            customer_id: null,
            business_id: dataEdit.business_id,
            outlet_id: dataEdit.outlet_id,
            transaction_id: 0,
            status: 'active',
            topup_id: null,
            cashback_id: null,
            type: 'transaction',
            balance: dataEdit.acquisition_cost
          }
          console.log('reduce_saldo_customer', reduce_saldo_customer)
          await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-saldo-history/reduce-saldo`, reduce_saldo_customer)
        }

        await this.handleCustomerVoucherPersonal({ customer_account_id: this.customerAccountId })
        await this.handleDataCustomer({ id: this.customerAccountId })
        this.$toasted.success('Success redeem voucher discount', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
      } catch (error) {
        console.log('error', error.response.data.message)
        let message = 'Failed redeem voucher discount'
        if (error.response) {
          if (error.response.data.message.includes('Daily Claim Limit')) {
            message = 'Daily Claim Limit reached'
          } else if (error.response.data.message.includes('Claim limit has been exceeded')) {
            message = 'Cannot claim Voucher. Claim limit has been exceeded'
          } else if (error.response.data.message.includes('not enough')) {
            message = error.response.data.message
          }
        }
        this.$toasted.error(message, {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
      }
    },
    handleType () {
      if (this.acquisition_type === 'currency') {
        this.acquisition_type = 'point'
      } else {
        this.acquisition_type = 'currency'
      }
    },
    async checkStatusPaymentCz (url) {
      console.log('urlnya', url)
      const result = await axios.post('https://api-link.cashlez.com/validate_url', {
        status: '',
        message: '',
        data: {
          request: {
            generatedUrl: url
          }
        }
      })
      console.log('result.data.data', result.data.data)
      console.log('result.data.data.response.processStatus', result.data.data.response.processStatus)
      return result.data.data
    },
    async handleCZ (params) {
      if (!params.Business.cz_entity_id || !params.Business.cz_pin || !params.Business.cz_user || !params.Business.cz_vendor_identifier) {
        this.$toasted.error('This outlet has not been integrated with the payment method', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
        return
      }
      if (params.claim_amount >= params.limit_claim) {
        this.$toasted.error('Cannot claim Voucher. Claim limit has been exceeded', {
          theme: 'bubble',
          position: 'top-right',
          duration: 4500
        })
        return
      }
      try {
        this.dataEdit = params
        this.stateModalCz = true
        console.log('params', params)
        const generateSignature = {
          data: {
            request: {
              vendorIdentifier: params.Business.cz_vendor_identifier,
              token: '',
              referenceId: this.TRANSIDMERCHANT,
              entityId: params.Business.cz_entity_id,
              merchantName: params.Outlet.name,
              merchantDescription: 'Cashlez Sunter',
              currencyCode: 'IDR',
              amount: params.acquisition_cost,
              callbackSuccess: '',
              callbackFailure: '',
              message: '',
              description: 'Test Transaction',
              transactionUsername: params.Business.cz_user
            }
          }
        }
        const { data } = await axios.post('https://api.beetpos.com/api/v1/signature/generate', generateSignature)
        console.log('data.data', data.data[0].result)

        generateSignature.signature = data.data[0].result

        console.log('generateSignature', generateSignature)
        const generateUrlVendor = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/signature/generate-url-vendor`, generateSignature)
        console.log('generateUrlVendor', generateUrlVendor.data.data)
        this.urlVendor = generateUrlVendor.data.data.response.generatedUrl
      } catch (error) {
        console.log('error cashlez', error)
      }
    },
    async handleTransactionCz (params) {
      try {
        const paymentMethods = await this.handlePaymentMethodBusiness({ businessId: this.dataEdit.Business.id })
        console.log('paymentMethods di halaman redeem', paymentMethods)
        console.log('data handleTransactionCz', params)

        let paymentMethod
        let paymentMethodId
        if (params.response.paymentType.id === 1) paymentMethod = 'ecomm'
        if (params.response.paymentType.id === 2) paymentMethod = 'virtual'
        if (params.response.paymentType.id === 3) paymentMethod = 'ovo'
        if (params.response.paymentType.id === 4) paymentMethod = 'qr'
        if (params.response.paymentType.id === 7) paymentMethod = 'virtual'

        console.log('this.getPaymentMethodBusiness', this.getPaymentMethodBusiness)
        console.log('this.dataEdit', this.dataEdit)

        this.getPaymentMethodBusiness.map(value => {
          if (value.cz_type === paymentMethod) {
            paymentMethodId = value.id
          }
        })
        const data_customer_voucher_personal = {
          outlet_id: this.dataEdit.outlet_id,
          customer_id: this.getCustomerId,
          voucher_id: this.dataEdit.id,
          voucher_expiration: this.dataEdit.expiration_date,
          status: 'available'
        }

        const { data } = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-voucher-personal`, data_customer_voucher_personal)

        const data_customer_voucher_redeem = {
          outlet_id: this.dataEdit.outlet_id,
          customer_id: this.getCustomerId,
          voucher_id: this.dataEdit.id,
          personal_voucher_id: data.data.id,
          voucher_expiration: this.dataEdit.expiration_date,
          payment_method_id: paymentMethodId,
          status: 'new'
        }

        await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-voucher-redeem/redeem`, data_customer_voucher_redeem)
        await this.handleCustomerVoucherPersonal({ customer_account_id: this.customerAccountId })
        await this.handleDataCustomer({ id: this.customerAccountId })
        this.$toasted.success('Success redeem voucher discount', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
      } catch (error) {
        this.$toasted.error('Failed redeem voucher discount', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
        console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer', 'getDataVoucherCustomer', 'getCustomerId', 'getDataCustomerVoucherPersonal', 'getPaymentMethodBusiness', 'getCustomerPoints']),
    voucherCustomer () {
      // Old Function
      const voucher_id_personal = this.getDataCustomerVoucherPersonal.map(value => value.voucher_id)
      const second_filter = this.getDataVoucherCustomer.filter(item => !voucher_id_personal.includes(item.id))

      // New Function
      const result = []
      this.getDataVoucherCustomer.map((value, index) => {
        const data = {
          ...value,
          outlet_name: value.Outlet ? value.Outlet.name : null,
          outlet_id: value.Outlet ? value.Outlet.id : null,
          voucher_name: value.name,
          format_expiration_date: dayjs(value.expiration_date).format('DD/MM/YYYY HH:mm'),
          format_from_now: dayjs(value.expiration_date).fromNow()
        }
        result.push(data)
      })
      return result
    }
  },
  watch: {
    acquisition_type (val) {
      this.handleVoucherCustomer({ acquisition_type: val })
    },
    urlVendor (val) {
      const looping = setInterval(async () => {
        const data = await this.checkStatusPaymentCz(val)
        console.log('watch urlVendor', data)
        await this.checkStatusPaymentCz(val)
        console.log('this.checkStatusPaymentCz()', await this.checkStatusPaymentCz(val))
        console.log('hooknya', data)
        if (!this.stateModalCz) {
          console.log('Event close modal')
          clearInterval(looping)
        }
        if (data.response.processStatus === 'APPROVED') {
          await this.handleTransactionCz(data)
          console.log('Data ketika Approved', data)
          clearInterval(looping)
        }
      }, 3000)
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

h3, h6{
  padding: 0;
  margin: 0;
}
.content{
  height: 90vh;
  overflow-y: auto;
  padding: 10px 20px;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-redeem{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrapper-image{
  width: 80px;
  height: 80px;
}
.wrapper-image>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.line{
  width: 100%;
  height: 12px;
  background-color: #E7E7FF;
  border-radius: 10px;
  margin: 10px 0;
}
::-webkit-scrollbar {
  width: 2px;
  height: 0;
}
.wrapper-list-voucher{
  height: 62vh;
  overflow: auto;
}
.list-voucher{
  border-radius: 3px;
  display: flex;
  background-color: #E7E7FF;
  /* padding: 25px; */
}
.list-voucher-left{
  width: 65%;
  padding: 15px;
  color: #6B4EFF;
  border: none;
}
.list-voucher-right{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  border: none;
  border-left-width: 0.4px;
  border-left-style: dashed;
  border-left-color: grey;
  margin: 5px 0;
}
.spent-button{
  font-size: 0.8rem;
  padding: 5px 20px;
  border-radius: 2px;
  color: white;
  background-color: #6B4EFF;
}
.vouchers-out{
  font-size: 0.8rem;
  padding: 5px 20px;
  border-radius: 2px;
  color: white;
  background-color: rgb(156, 156, 156);
}
.business-outlet{
  font-size: 0.8rem;
}
.amount-discount{
  font-size: 1.2rem;
  font-weight: 600;
}
.max-shopping{
  font-size: 0.8rem;
}
.expiration-date{
  color: #6C757E;
  font-size: 0.8rem;
}
.text-points{
  font-size: 1rem;
  font-weight: 600;
  margin: 10px 0;
}
.badge-primary{
  width: 70px;
  padding: 10px 0;
  background-color: #6B4EFF;
}
.custom-modal{
  top: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.modal-box{
  background-color: rgb(242,247,252);
  box-shadow: 0 0 3px 1px #6B4EFF;
  width: 60%;
}
.modal-box > iframe {
  width: 100%;
  height: 100%;
}
.header-custom-modal {
  background-color: rgb(242,247,252);
}
.wrapper-icon-close{
  margin: 10px 25px;
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.wrapper-icon-close>img{
  width: 100%;
  height: 100%;
}
.wrapper-modal-filter{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-filter{
  padding: 0 10px;
  padding-top: 10px;
  width: 70%;
  background-color: white;
  border-radius: 5px;
}
.list-filter{
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.list-filter:hover{
  background-color: #6B4EFF;
  color: white;
}
.hidden{
  display: none;
}
.select{
  background-color: #6B4EFF;
  color: white;
}
@media screen and (max-width: 786px) {
  .modal-box{
    background-color: white;
    width: 85%;
  }
  .header-custom-modal {
    background-color: white;
  }
}
@media only screen and (max-width: 495px) {
  .modal-box{
    border: 1px solid;
    width: 95%;
  }
}
</style>
