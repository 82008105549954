<template>
  <div class="container-profile">
    <div :class="[show_modal_language ? 'wrapper-modal-language' : 'hidden']" @click="closeModalLanguage">
      <div class="modal-language">
        <div class="list-language" v-for="(data, index) in options" :key="index" :class="{select: data.value === selected.value}" @click="handleSelectLanguage(data)">
          {{data.label}}
        </div>
      </div>
    </div>
    <div class="profile-top"  style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <div style="width: 100%">
        <div class="my-detail">
          {{$t('my_detail')}}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-3"  style="width: 100%">
      <div class="wrapper-image">
        <img  v-if="getDataCustomer.image" :src="getDataCustomer.image" alt="">
        <img  v-else src="@/assets/images/profile.jpg" alt="">
      </div>
      <label class="button-small mb-3 feedback" for="upload-image">{{$t('change')}}</label>
      <!-- <input type="file" name="image" id="upload-image" @change="uploadImage"/> -->
      <!-- <label class="img-profile-toggle"> -->
        <input id="upload-image" type="file" @change="uploadImage"/>
      <!-- </label> -->
    </div>
    <div class="wrapper-list">
      <div>{{$t('name')}}</div>
      <div v-show="!showField('name')" @click="focusField('name')">{{getDataCustomer.name}}</div>
      <input v-model="getDataCustomer.name" v-show="showField('name')" id="user-name" type="text" class="field-value name" @focus="focusField('name', getDataCustomer.name)" @blur="blurField" v-on:keyup.enter="blurField">
    </div>
    <div class="wrapper-list">
      <div>{{$t('phone_number')}}</div>
      <div v-show="!showField('phone_number')" @click="focusField('phone_number')">{{getDataCustomer.phone_number}}</div>
      <input v-model="getDataCustomer.phone_number" v-show="showField('phone_number')" id="user-phone_number" type="text" class="field-value phone_number" @focus="focusField('phone_number', getDataCustomer.phone_number)" @blur="blurField" v-on:keyup.enter="blurField">
    </div>
    <!-- <div class="wrapper-list">
      <div>Last name</div>
      <div>Ayunda</div>
    </div> -->
    <div class="wrapper-list">
      <div>{{$t('location')}}</div>
      <div>Indonesia</div>
    </div>
    <div class="section">{{$t('account_information')}}</div>
    <div class="wrapper-list">
      <div>{{$t('email')}}</div>
      <div v-show="!showField('email')" @click="focusField('email')">{{getDataCustomer.email}}</div>
      <input v-model="getDataCustomer.email" v-show="showField('email')" id="user-email" type="text" class="field-value email" @focus="focusField('email', getDataCustomer.email)" @blur="blurField" v-on:keyup.enter="blurField">
    </div>
    <!-- <p class="tip">*please double click for edit</p> -->
    <div class="section">{{$t('international_preferences')}}</div>
    <div class="wrapper-list" style="cursor: pointer" @click="show_modal_language = !show_modal_language">
      <div>
        <div>{{$t('language')}}</div>
        <div>{{selected.label}}</div>
      </div>
      <div>></div>
    </div>
    <!-- <select v-model="lang" name="language" @change="(e) => handleLanguage(e)">
      <option value="en">English</option>
      <option value="id">Indonesia</option>
    </select> -->
    <!-- <v-select class="style-chooser" v-model="selected" :clearable="false" :options="options" @input="setSelected"></v-select> -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'Profile',
  data () {
    const lang = localStorage.getItem('lang') || 'en'
    const options = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'id',
        label: 'Indonesia'
      }
    ]
    const selected = options.find(value => {
      return value.value == lang
    })
    return {
      image: '',
      name: '',
      email: '',
      phone_number: '',
      editField: '',
      lang: lang,
      selected,
      options,
      show_modal_language: false
    }
  },
  mounted () {
    // this.getDataCustomer = this.getDataCustomer
  },
  methods: {
    ...mapActions(['handleDataCustomer', 'handleUpdateImageCustomer']),
    async handleSelectLanguage (data) {
      try {
        this.selected = data
        localStorage.setItem('lang', data.value)
        await axios.patch(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/language/${localStorage.getItem('customer_account_id')}`, { language: data.value })
        window.location.reload()
      } catch (error) {
        console.log('error', error)
      }
    },
    closeModalLanguage () {
      this.show_modal_language = !this.show_modal_language
    },
    focusField (params, params2) {
      this.editField = params
      if (params2) {
        if (params2.includes('Please insert')) {
          this.getDataCustomer[params] = ''
        }
      }
    },
    async uploadImage (e) {
      try {
        const image = e.target.files[0]
        this.image = image
        const formData = new FormData()

        if (this.image) {
          formData.append('image', this.image)
        }
        await this.handleUpdateImageCustomer({ id: localStorage.getItem('customer_account_id'), data: formData })
      } catch (error) {
        console.log('error', error)
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    showField (params) {
      return (this.getDataCustomer[params] == '' || this.editField == params)
    },
    async blurField () {
      this.editField = ''
      console.log('ketika klik luar')
      console.log('data akhir', this.getDataCustomer)
      const formData = new FormData()
      formData.append('name', this.getDataCustomer.name)
      formData.append('email', this.getDataCustomer.email)
      formData.append('phone_number', this.getDataCustomer.phone_number)
      formData.append('primary_address', this.getDataCustomer.primary_address)
      // if (this.image) {
      //   formData.append("image", this.image)
      // }
      const { data } = await axios.put(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/${localStorage.getItem('customer_account_id')}`, formData)
      await this.handleDataCustomer({ id: localStorage.getItem('customer_account_id') })
    },
    handleLanguage (e) {
      window.location.reload()
      localStorage.setItem('lang', e.target.value)
    },
    setSelected (value) {
      localStorage.setItem('lang', value.value)
      window.location.reload()
      this.selected = {
        value: value.value,
        label: value.label
      }
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer'])
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.profile-top{
  display: flex;
  align-items: center;
  padding-right: 50px;
}
.container-profile{
  height: 90vh;
  overflow-y: auto;
}
.back-button{
  margin: 20px;

}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.my-detail{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrapper-image{
  width: 80px;
  height: 80px;
}
.wrapper-image>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.button-small{
  margin-top: 20px;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 25px;
  background-color: #E7E7FF;
  color: #6B4EFF;
  font-weight: 600;
}
.tip{
  font-size: 0.8rem;
  margin: 10px 20px;
  color: #6B4EFF;
}
.wrapper-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-width: 0;
  /* border-style: solid;
  border-top-width: thin; */
}
.wrapper-list > input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #6B4EFF;
  text-align: end;
}
.wrapper-list > input:focus {
  /* outline-color: #6B4EFF; */
  outline: none;
}
.section{
  /* border-top: 0.1px solid grey; */
  background-color: #F7F9FA;
  padding: 20px;
}
input[type="file"] {
  display: none;
}

.style-chooser .vs__dropdown-toggle{
  background: #dfe5fb;
  border: none !important;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
.wrapper-modal-language{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-language{
  padding: 0 10px;
  padding-top: 10px;
  width: 70%;
  background-color: white;
  border-radius: 5px;
}
.list-language{
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.list-language:hover{
  background-color: #6B4EFF;
  color: white;
}
.hidden{
  display: none;
}
.select{
  background-color: #6B4EFF;
  color: white;
}
@media screen and (max-width: 768px) {
  .verify{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .modal-language{
    width: 70%;
  }
}
@media screen and (max-width: 495px) {
  ::-webkit-scrollbar {
    width: 0;
    height: 5px;
  }
  .modal-language{
    width: 95%;
  }
}
</style>
