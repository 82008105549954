<template>
  <div class="content">
    <div class="d-flex align-items-center" style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <div style="width: 100%">
        <div class="text-forgot-password">
          Forgot Password
        </div>
      </div>
    </div>
    <div>
      <div class="title-input" for="email">Email</div>
      <input class="email" type="text" id="email" v-model="email">
      <p class="text-danger" v-if="email.length >=1 && !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)">email must be a valid email</p>
    </div>
    <div>
      <button :class="[!email.length || !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? 'disabled' : 'active'  ,'button mt-3']" :disabled="!email.length || !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)" @click="handleSend">
        Send
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async handleSend () {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-account-noverify/forgot-password`, { email: this.email })
        Swal.fire(
          '',
          'please check your email for reset new password',
          'success'
        )
      } catch (error) {
        Swal.fire(
          "Something wen't wrong!",
          '',
          'error'
        )
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-forgot-password{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.title-input{
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 4px;
}
input.email{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  margin-left: -2px;
  /* padding: 8px 8px; */
  text-justify: auto;
  height: 43px;
  padding-left: 7px;
}
input.email:focus{
  outline: 0.3px #6B4EFF solid;
}
.button{
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.disabled {
  background-color: #E3E5E6;
  color: #979C9E;
}
.active{
  background-color: #6B4EFF;
  color: white;
}
.button{
  border: none;
}
button:focus{
  outline: none;
}
</style>
