<template>
  <div>
    <!-- <div class="top-color" /> -->
    <div class="wrapper-card-login">
      <div class="form-auth">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Auth'
}
</script>

<style scoped>
.top-color{
  top: 0;
  position: absolute;
  background-color: #623863;
  width: 100%;
  height: 10px;
}
.wrapper-card-login{
  width: 100%;
}
.form-auth{
  position: relative;
  padding: 10px 20px;
  padding-bottom: 20px;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .form-auth{
    width: 100%;
  }
}
@media screen and (max-width: 495px) {
  /* .form-auth{
    width: 100%;
  } */
}
</style>
